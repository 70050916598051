import { Input, Button } from "../../../../ui/atoms";
import { useStyles } from "./Tickets.styles";
// import { DropzoneBox } from "../../../../ui/atoms/dropZoneBox/DropZoneBox";
import { useMemo, useState } from "react";
import { CreateTicketsProps } from "./Tickets.types";
import { Column } from "react-table";
import { Table } from "../../../../ui/organisms/table/Table";
import { Modal } from "../../../../ui/organisms/modal/Modal";
import {
  DialogContent,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
// import { useClient } from "../../../../hooks/useClient/useClient";
import { SuccessData } from "../../../../api/boardRoom/tickets/tickets.types";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { PDFViewer } from "react-view-pdf";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { EditTicketModalContainer } from "./editTicketModal/EditTicketModalContainer";
import { colors } from "../../../../constants/colors";
// import {
//   useEditTicket,
//   useEditTicketStatus,
//   useGetTicketById,
//   useGetTicketStatus,
// } from "../../../../api/boardRoom/tickets/tickets";
// import { toast } from "react-toastify";

export const Tickets = ({
  register,
  onSubmit,
  errors,
  onDropHandleFunc,
  thumbnail,
  ticketsData,
  setOpenContactsModal,
  setOpenAddModal,
  isOpenContactsModal,
  isOpenAddModal,
  handleDeleteTicket,
  openEditTicketModal,
  setOpenEditTicketModal,
  setTicketId,
  getTicketsDataById,
  disableSubmitButton,
  date,
  setDate,
  effectiveDate,
  setEffectiveDate,
  reqId,
  setReqId,
  selectedStatus,
  setSelectedStatus,
  selectedDescription,
  setSelectedDescription,
  descriptionsData,
  handleOnSubmit,
  selectedRow,
  setSelectedRow,
  reloadEditModal,
  setReloadEditModal,
  statusId,
  setStatusId,
}: CreateTicketsProps) => {
  const styles = useStyles();
  const [isOpenPDFModal, setOpenPDFModal] = useState(false);
  const [file, setFile] = useState("");
  const [userId, setUserId] = useState(0);
  const [Id, setId] = useState("");
  console.log(setFile);
  // const accept = {
  //   "application/pdf": [],
  // };
  // const client = useClient();
  // const openInNewTab = (url: string) => {
  //   window.open(url, "_blank", "noopener,noreferrer");
  // };

  const columns: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "radio",
        Header: "",
        accessor: "id",
        Cell: ({ value }) => {
          return (
            <div
              style={{
                // backgroundColor: "red",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Radio
                key={`radio-${value}`}
                checked={selectedRow === value}
                onClick={() => {
                  setSelectedRow(value);
                  setReqId(value);
                }}
                value={value}
              />
              <div className={styles.truncatedText}>{value}</div>
            </div>
          );
        },
      },
      // {
      //   id: "id",
      //   Header: "Id",
      //   accessor: "id",
      //   Cell: ({ value }) => (
      //     <div className={styles.truncatedText}>{value}</div>
      //   ),
      // },
      // {
      //   id: "check",
      //   Header: <input type="checkbox" style={{ marginLeft: "12px" }} />,
      //   accessor: "id",
      //   Cell: ({ value }) => {
      //     return (
      //       <div className={styles.truncatedText}>
      //         <input
      //           type="checkbox"
      //           value={value}
      //           key={value}
      //           onClick={(event) => {
      //             // handleIndividualChecked(event);
      //             setReqId(value);
      //           }}
      //         />
      //       </div>
      //     );
      //   },
      // },
      {
        id: "document_name",
        Header: "Document Name",
        accessor: "document_name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "created_at",
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {moment(value).format("YYYY-MM-DD")}
          </div>
        ),
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "document_effect_date",
        Header: "Document effect Date",
        accessor: "document_effect_date",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {moment(value).format("YYYY-MM-DD")}
          </div>
        ),
      },
      {
        id: "status",
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {value === "submitted" ? (
              <Button style={{ background: "#ff9800", color: "#ffffff" }}>
                {value}
              </Button>
            ) : (
              <Button style={{ background: "#2196f3", color: "#ffffff" }}>
                {value}
              </Button>
            )}
          </div>
        ),
      },
      {
        id: "status_description",
        Header: "Status Description",
        accessor: "status_description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({
          row: {
            original: { files, id },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  setTicketId(`${id}`);
                  setId(`${id}`);
                  setReloadEditModal(true);
                  await setOpenEditTicketModal(true);
                }}
              >
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              </Button>
              <Button
                className={styles.buttonView}
                variant="text"
                onClick={async () => {
                  await setUserId(id);
                  setOpenContactsModal(true);
                }}
              >
                <Tooltip title="Delete">
                  <DeleteOutlineIcon />
                </Tooltip>
              </Button>
            </div>
          </div>
        ),
      },
    ],
    [
      selectedRow,
      // ticketsData?.data,
      setOpenContactsModal,
      setOpenEditTicketModal,
      setReloadEditModal,
      setReqId,
      setSelectedRow,
      setTicketId,
      styles.actionButtons,
      styles.buttonView,
      styles.truncatedText,
    ]
  );

  const statusOptions = [
    { id: 1, status: "Docs Generated" },
    { id: 2, status: "Waiting for client Action" },
    { id: 3, status: "Submission" },
    { id: 4, status: "Processing" },
    { id: 5, status: "Approved" },
    { id: 6, status: "Completed" },
  ];

  const setStatusIdFunction = (status: string) => {
    let id;
    switch (status) {
      case "Docs Generated":
        id = 1;
        break;
      case "Waiting for client Action":
        id = 2;
        break;
      case "Submission":
        id = 3;
        break;
      case "Processing":
        id = 4;
        break;
      case "Approved":
        id = 5;
        break;
      case "Completed":
        id = 6;
        break;
      default:
        console.error("Unknown status:", status);
        return;
    }
    setStatusId(id);
  };

  return (
    <div className={styles.mainDiv}>
      <div
        style={{
          justifyContent: "flex-end",
          alignSelf: "flex-end",
        }}
      >
        {/* <Button
          type="button"
          size="large"
          variant="outlined"
          bold
          className={styles.submit}
          onClick={() => setOpenAddModal(true)}
          hidden={true}
        >
          Add Tickets
        </Button> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 15,
          width: "100%",
        }}
      >
        {/* <div
          style={{
            color: colors.primary,
            // paddingLeft: 30,
            // paddingRight: 30,
            fontSize: 20,
            alignSelf: "center",
            fontWeight: "bold",
          }}
        >
          Change Status
        </div> */}
        <div>
          <div style={{ position: "absolute", top: 140 }}>
            <InputLabel style={{ color: "black" }}>Select Status</InputLabel>
          </div>
          <Select
            style={{ width: "250px" }}
            placeholder="Select Status"
            value={selectedStatus}
            onChange={(event) => {
              setSelectedStatus(event.target.value);
              setStatusIdFunction(event.target.value);
            }}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.id} value={option.status}>
                <ListItemText primary={option.status} />
              </MenuItem>
            ))}
          </Select>
        </div>

        <div>
          <div style={{ position: "absolute", top: 140 }}>
            <InputLabel style={{ color: "black" }}>
              Select Description
            </InputLabel>
          </div>
          <Select
            style={{ width: "250px" }}
            placeholder="Select Status"
            value={selectedDescription}
            onChange={(event) => setSelectedDescription(event.target.value)}
          >
            {descriptionsData &&
              descriptionsData.length > 0 &&
              descriptionsData.map((status, index) => (
                <MenuItem key={index} value={status}>
                  <ListItemText primary={status} />
                </MenuItem>
              ))}
          </Select>
        </div>
        <Button
          type="button"
          size="large"
          variant="outlined"
          bold
          style={{
            color: "white",
            paddingLeft: 30,
            paddingRight: 30,
            backgroundColor: colors.primary,
          }}
          onClick={() => handleOnSubmit()}
        >
          Submit
        </Button>
        <Button
          type="button"
          size="large"
          variant="outlined"
          bold
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            // backgroundColor: colors.primary,
            color: colors.primary,
          }}
          onClick={() => setOpenAddModal(true)}
        >
          Add Tickets
        </Button>
      </div>
      {ticketsData?.data && ticketsData?.data?.length > 0 ? (
        <Table
          columns={columns}
          data={ticketsData?.data || []}
          className={styles.table}
        />
      ) : (
        <>No DataAvailable</>
      )}
      {isOpenAddModal && (
        <Modal open={isOpenAddModal} onClose={() => setOpenAddModal(false)}>
          <form noValidate onSubmit={onSubmit} className={styles.cardForm}>
            <DialogContent>
              <div className={styles.head}>
                <div className={styles.rowDiv}>
                  <Typography
                    align="center"
                    variant="h4"
                    className={styles.lable}
                  >
                    Add Ticket
                  </Typography>
                  <InputLabel style={{ color: "black" }}>Date</InputLabel>
                  <ReactDatePicker
                    selected={date}
                    onChange={(date) => setDate(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="DD/MM/YYYY"
                  />
                  <Input
                    required
                    label="Title / Document Name"
                    aria-label="document_name"
                    placeholder={"Enter document name"}
                    name="document_name"
                    className={styles.input}
                    error={!!errors?.document_name}
                    helperText={errors?.document_name?.message}
                    register={register}
                  />
                  <Input
                    required
                    label="Description"
                    aria-label="description"
                    placeholder={"Enter description"}
                    name="description"
                    className={styles.input}
                    error={!!errors?.description}
                    helperText={errors?.description?.message}
                    register={register}
                  />
                  <InputLabel style={{ color: "black" }}>
                    Document Effect Date
                  </InputLabel>
                  <ReactDatePicker
                    selected={effectiveDate}
                    onChange={(date) => setEffectiveDate(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="DD/MM/YYYY"
                  />
                </div>
              </div>
            </DialogContent>
            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setOpenAddModal(false)}
                  variant="outlined"
                  className={styles.buttonyes}
                >
                  Cancel
                </Button>

                <Button
                  // disabled={disableSubmitButton}
                  size="large"
                  type="submit"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.closeButton}
                >
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isOpenContactsModal && (
        <Modal
          open={isOpenContactsModal}
          onClose={() => setOpenContactsModal(false)}
        >
          <DialogContent>
            <div className={styles.head}>
              <Typography align="center" variant="h5" className={styles.lable}>
                Are you sure you want to delete this ticket?
              </Typography>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenContactsModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                No
              </Button>

              <Button
                size="large"
                type="button"
                variant="outlined"
                color="inherit"
                bold
                className={styles.buttonyes}
                onClick={async () => {
                  await handleDeleteTicket(userId);
                  setOpenContactsModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isOpenPDFModal && (
        <Modal
          open={isOpenPDFModal}
          onClose={() => setOpenPDFModal(false)}
          style={{ width: "100%", height: "90%" }}
        >
          <DialogContent>
            <div className={styles.head}>
              {file?.includes("pdf") ? (
                <PDFViewer url={file} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img src={file} width="500" height="500" alt="img" />
                </div>
              )}
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenPDFModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                Ok
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {openEditTicketModal && (
        <EditTicketModalContainer
          isOpen={openEditTicketModal}
          onClose={() => setOpenEditTicketModal(false)}
          ticketId={Id}
          getTicketsDataById={getTicketsDataById}
          // requestCCopyId={String(reqId)}
          // handleStatusChangeAPICall={handleStatusChangeAPICall}
          reloadEditModal={reloadEditModal}
          setReloadEditModal={setReloadEditModal}
        />
      )}
    </div>
  );
};
