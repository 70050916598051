import { useForm } from "react-hook-form";
import {
  EditRegistrationDeskFormInputs,
  ManageRequestModalContainersProps,
} from "./EditTicketModal.types";
import { ManageRequestModal } from "./EditTicketModal";
// import { useClient } from "../../../../../hooks/useClient/useClient";
import { SetStateAction, useEffect, useState } from "react";
import { CreateRegistrationDeskSchema } from "../Tickets.utils";
import { useLocale } from "../../../../../hooks/useLocale/useLocale";
import {
  useEditTicket,
  useGetTicket,
} from "../../../../../api/boardRoom/tickets/tickets";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../../../hooks/useClient/useClient";
import { AppRoute } from "../../../../../routing/AppRoute.enum";
import { TabTypes } from "../../BoardRoomTabsEnum";
import moment from "moment";

export const EditTicketModalContainer = ({
  getTicketsDataById,
  ticketId,
  onClose,
  setReloadEditModal,
  reloadEditModal,
  ...props
}: ManageRequestModalContainersProps) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState<File>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [date, setDate] = useState<Date | null>(new Date());
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(new Date());
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<EditRegistrationDeskFormInputs>({
    resolver: CreateRegistrationDeskSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { document_name, description } = watch();

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("RegFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
        localStorage.setItem("RegFile", reader.result as string);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const { refetch } = useGetTicket({
    client,
    id: `${getTicketsDataById?.data.boardroom_id}`,
  });

  useEffect(() => {
    setValue("document_name", getTicketsDataById?.data.document_name);
    setValue("description", getTicketsDataById?.data.description);

    setEffectiveDate(
      moment(getTicketsDataById?.data.document_effect_date).toDate()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTicketsDataById,
    setValue,
    getTicketsDataById?.data.document_effect_date,
  ]);

  const { mutate: handleEditTicket } = useEditTicket({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Ticket updated successfully");
      navigate(
        AppRoute.boardRoomDManagement.replace(
          ":id",
          getTicketsDataById?.data.boardroom_id as string
        ),
        {
          state: TabTypes.Tickets,
        }
      );
      onClose();
      refetch();
      setReloadEditModal(true);
      reset();
      // setInterval(() => window.location.reload(), 1000);
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to update data");
      navigate(
        AppRoute.boardRoomDManagement.replace(
          ":id",
          getTicketsDataById?.data.boardroom_id as string
        ),
        {
          state: TabTypes.Tickets,
        }
      );
      refetch();
      reset();
    },
  });

  const handleOnSubmit = () => {
    setIsLoading(true);
    setButtonDisabled(true);
    const editTicket = new FormData();
    editTicket.append("document_name", `${document_name}`);
    editTicket.append("description", `${description}`);
    editTicket.append(
      "document_effect_date",
      `${moment(effectiveDate).format("YYYY-MM-D")}`
    );

    handleEditTicket({
      client,
      body: editTicket,
      id: `${getTicketsDataById?.data.id}` as string,
    });
    // localStorage.removeItem("RegFile");
  };

  return (
    <ManageRequestModal
      reloadEditModal={false}
      setReloadEditModal={function (value: SetStateAction<boolean>): void {
        throw new Error("Function not implemented.");
      }}
      date={date}
      setDate={setDate}
      effectiveDate={effectiveDate}
      setEffectiveDate={setEffectiveDate}
      buttonDisabled={buttonDisabled}
      setButtonDisabled={setButtonDisabled}
      onTrackingModalSubmit={handleSubmit(handleOnSubmit)}
      register={register}
      ticketId={ticketId}
      errors={errors}
      thumbnail={thisFile}
      onDropHandleFunc={onDropHandleFunction}
      getTicketsDataById={getTicketsDataById}
      onClose={onClose}
      {...props}
    />
  );
};
