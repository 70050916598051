import { useMemo, useState } from "react";
import { Column } from "react-table";
import { useStyles } from "./ManageCustomer.styles";
import { Table } from "../../../ui/organisms/table/Table";
import {
  Button,
  DialogContent,
  Typography,
  // Input,
  Heading,
} from "../../../ui/atoms";
import { Modal } from "../../../ui/organisms/modal/Modal";
import { useClient } from "../../../hooks/useClient/useClient";
import { useGetAppUsers } from "../../../api/customerManagement/customerManagement";
import { more } from "../../../api/customerManagement/customerManagement.types";
import moment from "moment";
import { ManageCustomersProps } from "./ManageCustomer.types";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { TextField, Tooltip } from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { colors } from "./../../../constants/colors";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const ManageCustomers = ({
  onSubmit,
  setCustomerStatus,
  setId,
  boardRoomList,
  handleAssignBoardroom,
  setBoardRoom,
  boardRoom,
  handleUnAssignBoardroom,
  setAssignedBoardrooms,
  filteredAssignedBoardRooms,
  removedAssignedBoardrooms,
}: ManageCustomersProps) => {
  const styles = useStyles();
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const [isOpenUnassign, setIsOpenUnassign] = useState(false);
  const [isOpenActivateModal, setOpenActivateModal] = useState(false);
  const [activated, setActivated] = useState("");
  const client = useClient();

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setBoardRoom(typeof value === "string" ? value.split(",") : value);
  };

  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);

  const { data: appUserData, refetch } = useGetAppUsers({
    client,
    searchValue,
    page,
  });

  const columns: Column<more>[] = useMemo(
    () => [
      {
        id: "first_name",
        Header: "First Name",
        accessor: "first_name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "last_name",
        Header: "Last Name",
        accessor: "last_name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "phone",
        Header: "Phone",
        accessor: "phone",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "created_at",
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {moment(value).format("YYYY-MM-DD")}
          </div>
        ),
      },
      // {
      //   id: "updated_at",
      //   Header: "Updated At",
      //   accessor: "updated_at",
      //   Cell: ({ value }) => (
      //     <div className={styles.truncatedText}>
      //       {moment(value).format("YYYY-MM-DD")}
      //     </div>
      //   ),
      // },
      // {
      //   id: "is_active",
      //   Header: "Is Activated",
      //   accessor: "is_active",
      //   Cell: ({ value }) => (
      //     <div>{value === "1" ? "Activated" : "Not activated"}</div>
      //   ),
      // },
      {
        id: "comnapy_name",
        Header: "Company",
        accessor: "company_name",
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({
          row: {
            original: { is_active, id, userboardroom },
          },
        }) => (
          <div className={styles.truncatedText}>
            <div className={styles.actionButtons}>
              <div
                onClick={async () => {
                  await setId(id);
                  await setAssignedBoardrooms(userboardroom);
                  await setOpenContactsModal(true);
                }}
              >
                <Tooltip title="Assign Boardroom">
                  <AssignmentTurnedInIcon color="warning" />
                </Tooltip>
              </div>
              <div
                onClick={async () => {
                  await setId(id);
                  await setActivated(is_active);
                  if (is_active === "1") {
                    await setCustomerStatus("0");
                  } else {
                    await setCustomerStatus("1");
                  }
                  await setOpenActivateModal(true);
                }}
              >
                {is_active === "1" ? (
                  <Tooltip title="Activated">
                    <ToggleOnIcon color="success" />
                  </Tooltip>
                ) : (
                  <Tooltip title="De-activated">
                    <ToggleOffIcon color="error" />
                  </Tooltip>
                )}
              </div>
              <div
                onClick={async () => {
                  await setId(id);
                  await setAssignedBoardrooms(userboardroom);
                  await setIsOpenUnassign(true);
                }}
              >
                <Tooltip title="Unassign Boardroom">
                  <AssignmentTurnedInIcon color="error" />
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appUserData]
  );

  return (
    <div className={styles.root}>
      <Heading>
        <h1 style={{ marginBottom: 0 }}>Customer Management</h1>
      </Heading>
      <div className={styles.mainDiv}>
        <div className={styles.buttonDiv}>
          {/* <Button
          type="submit"
          size="large"
          variant="outlined"
          bold
          className={styles.submit}
          onClick={() => navigate(AppRoute.createCustomers)}
        >
          Add Customers
        </Button> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            width: "100%",
          }}
        >
          <TextField
            required
            aria-label="search"
            placeholder={"Search by Name, Mobile or Email"}
            name="search"
            value={searchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(event.target.value);
              setPage(0);
            }}
            className={styles.input}
            InputProps={{
              style: { borderColor: "red", borderWidth: 2 },
            }}
          />
          <Button
            style={{
              backgroundColor: colors.primary,
              color: "white",
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={() => {
              setPage(0);
              setSearchValue(searchValue);
              refetch();
            }}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: colors.primary,
              color: "white",
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={async () => {
              await setPage(1);
              await setSearchValue("");
              await refetch();
            }}
          >
            Reset
          </Button>
        </div>
        <Table
          columns={columns}
          data={appUserData?.data.data || []}
          className={styles.table}
        />
        {page > 0 && (
          <Button
            type="button"
            bold
            size="large"
            variant="text"
            style={{ color: "black" }}
          >
            Page {page}
          </Button>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "40%",
          }}
        >
          {page > 1 && (
            <Button
              type="button"
              bold
              size="large"
              onClick={async () => {
                await setPage(page - 1);
                await setSearchValue("");
                refetch();
              }}
              variant="text"
              className={styles.closeButton}
            >
              <KeyboardArrowLeftIcon color="success" />
              Previous
            </Button>
          )}

          {appUserData?.data?.last_page &&
            page < appUserData?.data?.last_page && (
              <Button
                type="button"
                bold
                size="large"
                onClick={async () => {
                  await setPage(page + 1);
                  await setSearchValue("");
                  refetch();
                }}
                variant="text"
                className={styles.closeButton}
              >
                Next
                <KeyboardArrowRightIcon color="success" />
              </Button>
            )}
        </div>
        {isOpenContactsModal && (
          <Modal
            open={isOpenContactsModal}
            onClose={() => {
              setBoardRoom([]);
              setAssignedBoardrooms([]);
              setOpenContactsModal(false);
            }}
          >
            <DialogContent>
              {removedAssignedBoardrooms.length > 0 ? (
                <>
                  <div className={styles.head}>
                    <Typography
                      align="center"
                      variant="h5"
                      className={styles.lable2}
                    >
                      Assign Board Room to customer
                    </Typography>
                  </div>
                  <div>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Board rooms
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      fullWidth
                      value={boardRoom}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {removedAssignedBoardrooms.map((name) => (
                        <MenuItem key={name.value} value={Number(name.value)}>
                          {/* <Checkbox checked={personName.indexOf(name.value) > -1} /> */}
                          <ListItemText
                            primary={name.label}
                            primaryTypographyProps={{ fontSize: "10px" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </>
              ) : (
                <div>
                  <Typography
                    align="center"
                    variant="h6"
                    className={styles.lable2}
                  >
                    No boardroom available
                  </Typography>
                </div>
              )}
            </DialogContent>
            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => {
                    setBoardRoom([]);
                    setAssignedBoardrooms([]);
                    setOpenContactsModal(false);
                  }}
                  variant="outlined"
                  className={styles.closeButton}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="button"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.buttonyes}
                  onClick={async () => {
                    await handleAssignBoardroom();
                    await refetch();
                    await setBoardRoom([]);
                    await setAssignedBoardrooms([]);
                    await setOpenContactsModal(false);
                  }}
                >
                  Assign
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {isOpenActivateModal && (
          <Modal
            open={isOpenActivateModal}
            onClose={() => setOpenActivateModal(false)}
          >
            <DialogContent>
              <div className={styles.head}>
                <Typography
                  align="center"
                  variant="h5"
                  className={styles.lable}
                >
                  {` Are you sure you want to ${
                    activated === "1" ? "inactive" : "activate"
                  } this user?`}
                </Typography>
              </div>
            </DialogContent>
            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => {
                    setOpenActivateModal(false);
                  }}
                  variant="outlined"
                  className={styles.buttonyes}
                >
                  No
                </Button>

                <Button
                  size="large"
                  type="button"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.closeButton}
                  onClick={async () => {
                    await onSubmit();
                    await refetch();
                    await setOpenActivateModal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
        {isOpenUnassign && (
          <Modal
            open={isOpenUnassign}
            onClose={() => {
              setAssignedBoardrooms([]);
              setBoardRoom([]);
              setIsOpenUnassign(false);
            }}
          >
            <DialogContent>
              {filteredAssignedBoardRooms.length > 0 ? (
                <>
                  <div className={styles.head}>
                    <Typography
                      align="center"
                      variant="h5"
                      className={styles.lable2}
                    >
                      Unassign Board Room to customer
                    </Typography>
                  </div>
                  <div>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Board rooms
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      fullWidth
                      value={boardRoom}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                    >
                      {filteredAssignedBoardRooms.map((name) => (
                        <MenuItem key={name.value} value={Number(name.value)}>
                          {/* <Checkbox checked={personName.indexOf(name.value) > -1} /> */}
                          <ListItemText primary={name.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </>
              ) : (
                <div>
                  <Typography
                    align="center"
                    variant="h6"
                    className={styles.lable2}
                  >
                    No boardroom is assigned to this user
                  </Typography>
                </div>
              )}
            </DialogContent>
            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => {
                    setAssignedBoardrooms([]);
                    setBoardRoom([]);
                    setIsOpenUnassign(false);
                  }}
                  variant="outlined"
                  className={styles.closeButton}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="button"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.buttonyes}
                  onClick={async () => {
                    await handleUnAssignBoardroom();
                    await refetch();
                    await setAssignedBoardrooms([]);
                    await setBoardRoom([]);
                    await setIsOpenUnassign(false);
                  }}
                >
                  Un-assign
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};
