import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { useStyles } from "./ManageBoardRoom.styles";
import { manageBoardRoomProps } from "./ManageBoardRoom.types";
import { Table } from "../../../ui/organisms/table/Table";
import {
  Button,
  DialogContent,
  Typography,
  // Input,
  Heading,
} from "../../../ui/atoms";
import { Modal } from "../../../ui/organisms/modal/Modal";
import { AppRoute } from "../../../routing/AppRoute.enum";
import { useClient } from "../../../hooks/useClient/useClient";
import { useGetBoardRooms } from "../../../api/boardRoom/boardRoom";
import {
  AssignedBoardRoomSuccessData,
  SuccessData,
} from "../../../api/boardRoom/boardRoom.types";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TextField, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { colors } from "../../../constants/colors";

export const ManageBoardRoom = ({
  handleDelete,
  setUserId,
  userId,

  boardRoomsUsers,
  getBoardroomUsers,
  setBoardRoomId,
  handleAssignedBoardRoomUsers,

  assignedBoardRoomUsers,
}: manageBoardRoomProps) => {
  const styles = useStyles();
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const [
    isOpenAssignedBoardRoomUserModal,
    setIsOpenAssignedBoardRoomUserModal,
  ] = useState(false);
  const navigate = useNavigate();
  const client = useClient();
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const { data: boardRooms, refetch } = useGetBoardRooms({
    client,
    searchValue,
    page,
  });

  console.log("boardroom", boardRooms);

  const AssignedBoardRoomUsersColumns: Column<AssignedBoardRoomSuccessData>[] =
    useMemo(
      () => [
        {
          id: "id",
          Header: "User ID",
          accessor: "user_id",
          Cell: ({ value }) => (
            <div className={styles.truncatedText}>{value}</div>
          ),
        },
        {
          id: "firstName",
          Header: "First Name",
          accessor: "user",
          Cell: ({ value }) => (
            <div className={styles.truncatedText}>{value.first_name}</div>
          ),
        },
        {
          id: "lastName",
          Header: "Last Name",
          accessor: "user",
          Cell: ({ value }) => (
            <div className={styles.truncatedText}>{value.last_name}</div>
          ),
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [assignedBoardRoomUsers?.data]
    );

  const columns: Column<SuccessData>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Board Room Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "description",
        Header: "Registration No",
        accessor: "description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "address",
        Header: "Address",
        accessor: "address",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "image",
        Header: "Logo",
        accessor: "image",
        Cell: ({ value }) => (
          <div className={styles.image}>
            <img
              src={value}
              alt="logo"
              height={50}
              width={50}
              style={{ borderRadius: 5, objectFit: "contain" }}
            />
          </div>
        ),
      },
      {
        id: "created_at",
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            {moment(value).format("YYYY-MM-DD")}
          </div>
        ),
      },
      // {
      //   id: "updated_at",
      //   Header: "Updated At",
      //   accessor: "updated_at",
      //   Cell: ({ value }) => (
      //     <div className={styles.truncatedText}>
      //       {moment(value).format("YYYY-MM-DD")}
      //     </div>
      //   ),
      // },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            <div
              className={styles.actionButtons}
              onClick={async (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                await setBoardRoomId(`${value}`);
                await handleAssignedBoardRoomUsers;
                setIsOpenAssignedBoardRoomUserModal(true);
              }}
            >
              <Tooltip title="Assigned Board Room Users">
                <VisibilityOutlinedIcon color="success" />
              </Tooltip>
            </div>
            <div
              className={styles.actionButtons}
              onClick={async (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                await setUserId(value);
                await getBoardroomUsers({
                  client,
                  id: value as unknown as string,
                });
                setOpenContactsModal(true);
              }}
            >
              <Tooltip title="Delete">
                <DeleteOutlineIcon color="error" />
              </Tooltip>
            </div>
            <div
              className={styles.actionButtons}
              onClick={async (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                await setUserId(value);
                await navigate(
                  AppRoute.editBoardRoom.replace(
                    ":id",
                    value as unknown as string
                  )
                );
              }}
            >
              <Tooltip title="Edit">
                <EditIcon color="success" />
              </Tooltip>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [boardRooms]
  );

  return (
    <div className={styles.root}>
      <Heading>
        <h1 style={{ marginBottom: 0 }}>Board Rooms</h1>
      </Heading>
      <div className={styles.mainDiv}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 15,
            width: "100%",
          }}
        >
          <TextField
            required
            aria-label="search"
            placeholder={"Search by Name & Registration No"}
            name="search"
            value={searchValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(event.target.value);
              // setPage(0);
            }}
            className={styles.input}
            InputProps={{
              style: { borderColor: "red", borderWidth: 2 },
            }}
          />
          <Button
            style={{
              backgroundColor: colors.primary,
              color: "white",
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={() => {
              // setPage(0);
              setSearchValue(searchValue);
              refetch();
            }}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: colors.primary,
              color: "white",
              paddingLeft: 30,
              paddingRight: 30,
            }}
            onClick={async () => {
              await setPage(1);
              await setSearchValue("");
              await refetch();
            }}
          >
            Reset
          </Button>
          <Button
            style={{
              // backgroundColor: colors.primary,
              color: colors.primary,
              paddingLeft: 30,
              paddingRight: 30,
            }}
            type="submit"
            size="large"
            variant="outlined"
            bold
            // className={styles.submit}
            onClick={() => navigate(AppRoute.createBoardRoom)}
          >
            Add Boardroom
          </Button>
        </div>
        <Table
          columns={columns}
          data={boardRooms?.data.data || []}
          className={styles.table}
          onRowClick={(row) => {
            navigate(AppRoute.boardRoomDManagement.replace(":id", `${row.id}`));
          }}
        />
        {page > 0 && (
          <Button
            type="button"
            bold
            size="large"
            variant="text"
            style={{ color: "black" }}
          >
            Page {page}
          </Button>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "40%",
          }}
        >
          {page > 1 && (
            <Button
              type="button"
              bold
              size="large"
              onClick={async () => {
                await setPage(page - 1);
                await setSearchValue("");
                refetch();
              }}
              variant="text"
              className={styles.closeButton}
            >
              <KeyboardArrowLeftIcon color="success" />
              Previous
            </Button>
          )}

          {boardRooms?.data?.last_page &&
            page < boardRooms?.data?.last_page && (
              <Button
                type="button"
                bold
                size="large"
                onClick={async () => {
                  await setPage(page + 1);
                  await setSearchValue("");
                  refetch();
                }}
                variant="text"
                className={styles.closeButton}
              >
                Next
                <KeyboardArrowRightIcon color="success" />
              </Button>
            )}
        </div>
        {isOpenContactsModal && (
          <Modal
            open={isOpenContactsModal}
            onClose={() => setOpenContactsModal(false)}
          >
            <DialogContent>
              <div className={styles.head}>
                <div>
                  <Typography
                    align="center"
                    variant="h5"
                    className={styles.lable}
                  >
                    Are you sure you want to delete this board room? (Deleting
                    this boardroom will remove any users assigned to this
                    boardroom)
                  </Typography>
                </div>
                <div
                  style={{
                    maxHeight: "130px",
                    overflow: "auto",
                    padding: "20px",
                  }}
                >
                  {boardRoomsUsers?.data.map((item, index) => (
                    <ul key={index}>
                      <li>
                        <Typography>{item.user.first_name}</Typography>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </DialogContent>
            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setOpenContactsModal(false)}
                  variant="outlined"
                  className={styles.closeButton}
                >
                  No
                </Button>

                <Button
                  size="large"
                  type="button"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.buttonyes}
                  onClick={async () => {
                    await handleDelete(userId);
                    await refetch();
                    window.location.reload();
                    setOpenContactsModal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}

        {isOpenAssignedBoardRoomUserModal && (
          <Modal
            open={isOpenAssignedBoardRoomUserModal}
            onClose={() => {
              setIsOpenAssignedBoardRoomUserModal(false);
              setBoardRoomId("");
            }}
          >
            <DialogContent>
              <Typography align="center" variant="h5" className={styles.lable}>
                Assigned Board Room Users List.
              </Typography>
              <Table
                columns={AssignedBoardRoomUsersColumns}
                data={assignedBoardRoomUsers?.data || []}
                className={styles.asignedBoardRoomUsersTable}
              />
            </DialogContent>

            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => {
                    setIsOpenAssignedBoardRoomUserModal(false);
                    setBoardRoomId("");
                  }}
                  variant="outlined"
                  className={styles.closeButton}
                >
                  Close
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};
