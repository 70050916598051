import { toast, ToastContainer } from "react-toastify";
import { useClient } from "../../hooks/useClient/useClient";
import { PushNotification } from "./PushNotification";
import { sendPushNotificationInputs } from "./PushNotification.types";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../routing/AppRoute.enum";
import { useState } from "react";
import {
  useGetAllBoardRoomData,
  useGetAllUserData,
  useSendPushNotification,
} from "../../api/pushNotification/sendPushNotification";

export const PushNotificationContainer = () => {
  const client = useClient();
  const navigate = useNavigate();

  const [sendTo, setSendTo] = useState("");
  const [selectedBoardRoomId, setSelectedBoardRoomId] = useState<string[]>([]);
  const [selectedUserMobileNumber, setSelectedUserMobileNumber] = useState<
    string[]
  >([]);

  const { data: getAllboardRoomData } = useGetAllBoardRoomData({
    client,
  });

  const { data: getAllUserData } = useGetAllUserData({
    client,
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    // setValue,
  } = useForm<sendPushNotificationInputs>({
    // mode: "onBlur",
    // reValidateMode: "onBlur",
  });

  const { title, content } = watch();

  const { mutate: sendPushNotification } = useSendPushNotification({
    onSuccess: () => {
      toast.success("Push notification sent successfully");
      navigate(AppRoute.pushNotification);
      reset();
      setSendTo("");
    },
    onError: () => {
      toast.error("Failed to sent Push notification");
    },
  });

  const handleBoardRoomChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedBoardRoomId(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleUserChange = (event: any) => {
    const {
      target: { value },
    } = event;
    console.log("value", value);
    setSelectedUserMobileNumber(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const findSelectedBoardRoomName = getAllboardRoomData?.data.filter((item1) =>
    selectedBoardRoomId.some((item2) => Number(item2) === item1.id)
  );

  const findSelectedUserName = getAllUserData?.data.filter((item1) =>
    selectedUserMobileNumber.some((item2) => item2 === `${item1.id}`)
  );

  // const { mutate: editAdminStatus } = useEditAdminStatus({
  //   onSuccess: () => {
  //     toast.success("Admin status updated successfully");
  //   },
  //   onError: () => {
  //     toast.error("Failed to update admin status");
  //   },
  // });

  const handleOnSubmit = () => {
    const SendPushNotification = new FormData();
    SendPushNotification.append("title", `${title}`);
    SendPushNotification.append("body", `${content}`);
    SendPushNotification.append("type", `${sendTo}`);
    SendPushNotification.append(
      "list",
      sendTo === "3"
        ? `[${selectedBoardRoomId}]`
        : sendTo === "2"
        ? `[${selectedUserMobileNumber}]`
        : `[]`
    );
    sendPushNotification({
      client,
      body: SendPushNotification,
    });
  };

  return (
    <>
      <PushNotification
        onSubmit={handleSubmit(handleOnSubmit)}
        register={register}
        getAllBoardRoomData={getAllboardRoomData}
        sendTo={sendTo}
        setSendTo={setSendTo}
        handleBoardRoomChange={handleBoardRoomChange}
        selectedBoardRoomId={selectedBoardRoomId}
        getAllUserData={getAllUserData}
        handleUserChange={handleUserChange}
        selectedUserMobileNumber={selectedUserMobileNumber}
        setSelectedUserMobileNumber={setSelectedUserMobileNumber}
        findSelectedBoardRoomName={findSelectedBoardRoomName}
        findSelectedUserName={findSelectedUserName}
      />
      <ToastContainer />
    </>
  );
};
