import {
  Button,
  DialogContent,
  Input,
  Typography,
} from "../../../../../ui/atoms";
import { useStyles } from "./EditTicketModal.styles";
import { ManageRequestModalProps } from "./EditTicketModal.types";
import { Modal } from "../../../../../ui/organisms/modal/Modal";
// import { DropzoneBox } from "../../../../../ui/atoms/dropZoneBox/DropZoneBox";
// import { PDFViewer } from "react-view-pdf";
import { InputLabel } from "@mui/material";
import ReactDatePicker from "react-datepicker";

export const ManageRequestModal = ({
  register,
  isOpen,
  onClose,
  onTrackingModalSubmit,
  errors,
  thumbnail,
  onDropHandleFunc,
  buttonDisabled,
  setButtonDisabled,
  date,
  setDate,
  effectiveDate,
  setEffectiveDate,
}: ManageRequestModalProps) => {
  const styles = useStyles();
  // const accept = {
  //   "application/pdf": [],
  // };
  return (
    <>
      <Modal open={isOpen} onClose={() => onClose()}>
        <form
          noValidate
          onSubmit={onTrackingModalSubmit}
          className={styles.cardForm}
        >
          <DialogContent>
            <div className={styles.head}>
              <div className={styles.rowDiv}>
                <Typography
                  align="center"
                  variant="h4"
                  className={styles.lable}
                >
                  Edit Ticket
                </Typography>
                <InputLabel style={{ color: "black" }}>Date</InputLabel>
                <ReactDatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="DD/MM/YYYY"
                />
                <Input
                  required
                  label="Title / Document Name"
                  aria-label="document_name"
                  placeholder={"Enter document name"}
                  name="document_name"
                  className={styles.input}
                  error={!!errors?.document_name}
                  helperText={errors?.document_name?.message}
                  register={register}
                />
                <Input
                  required
                  label="Description"
                  aria-label="description"
                  placeholder={"Enter description"}
                  name="description"
                  className={styles.input}
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  register={register}
                />
                <InputLabel style={{ color: "black" }}>
                  Document Effect Date
                </InputLabel>
                <ReactDatePicker
                  selected={effectiveDate}
                  onChange={(date) => setEffectiveDate(date)}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="DD/MM/YYYY"
                />
              </div>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                // onClick={() => setOpenAddModal(false)}
                variant="outlined"
                className={styles.buttonyes}
                onClick={() => onClose()}
              >
                Cancel
              </Button>

              <Button
                // disabled={buttonDisabled}
                // onClick={()=> setButtonDisabled(true)}
                size="large"
                type="submit"
                variant="outlined"
                color="inherit"
                bold
                className={styles.closeButton}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
