import { InputLabel } from "@mui/material";
import {
  Button,
  DialogContent,
  Input,
  // Select,
  Typography,
} from "../../../ui/atoms";
import { Modal } from "../../../ui/organisms/modal/Modal";
import { useStyles } from "./ManageRequestModal.styles";
// import PhoneInput from "react-phone-input-2";
import { ManageRequestModalProps } from "./ManageRequestModal.types";

export const ManageRequestModal = ({
  register,
  isOpen,
  onClose,
  onTrackingModalSubmit,
  requestCCopyId,
}: ManageRequestModalProps) => {
  const styles = useStyles();
  return (
    <Modal open={isOpen} onClose={() => onClose()}>
      <DialogContent>
        <div className={styles.head}>
          <Typography align="center" variant="h5" className={styles.lable}>
            Tracking Details.
          </Typography>
        </div>
        <form noValidate onSubmit={onTrackingModalSubmit}>
          <div className={styles.editFieldsMain}>
            <div className={styles.rowFields}>
              <div>
                <InputLabel>Tracking ID</InputLabel>
              </div>
              <div>
                <Input
                  name="tracking_id"
                  style={{ marginLeft: "21px", width: "305px", padding: "5px" }}
                  register={register}
                />
              </div>
            </div>
            <div className={styles.rowFields}>
              <div>
                <InputLabel>Tracking URL</InputLabel>
              </div>
              <div>
                <Input
                  name="tracking_url"
                  style={{ marginLeft: "5px", width: "305px", padding: "5px" }}
                  register={register}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              type="button"
              bold
              size="large"
              onClick={() => onClose()}
              variant="contained"
              className={styles.closeButton}
            >
              Cancel
            </Button>

            <Button
              size="large"
              type="submit"
              variant="contained"
              bold
              className={styles.buttonyes}
            >
              Update Status
            </Button>
          </div>
        </form>
      </DialogContent>
    </Modal>
  );
};
