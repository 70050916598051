import { useClient } from "../../../../hooks/useClient/useClient";
import { useLocale } from "../../../../hooks/useLocale/useLocale";
import { RegistrationDesk } from "./RegistrationDesk";
import { useForm } from "react-hook-form";
import { CreateRegistrationDeskInputs } from "./RegistrationDesk.types";
import { CreateRegistrationDeskSchema } from "./RegistrationDesk.utils";
import { toast, ToastContainer } from "react-toastify";
import {
  useAddRegistrationDesk,
  useDeleteRegistrationDesk,
  useGetRegistrationDesk,
  useGetRegistrationDeskById,
} from "../../../../api/boardRoom/registrationDesk/RegistartionDesk";
import { useState } from "react";
import { AppRoute } from "../../../../routing/AppRoute.enum";
import { useNavigate } from "react-router-dom";
import { TabTypes } from "../BoardRoomTabsEnum";
import LoadingIcon from "../../../../assets/icons/loading.gif";

export const RegistrationDeskContainer = ({
  id,
}: {
  id: string | undefined;
}) => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [openEditRegistrationDeskModal, setOpenEditRegistrationDeskModal] =
    useState(false);
  const [registrationDeskId, setRegistrationDeskId] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const {
    register,
    // handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateRegistrationDeskInputs>({
    resolver: CreateRegistrationDeskSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const { name, description, index } = watch();

  const { mutate: handleAddRegistrationDesk } = useAddRegistrationDesk({
    onSuccess: () => {
      setIsLoading(false);
      toast.success("Registration Desk added successfully");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.RegistrationDesk,
      });
      setOpenAddModal(false);
      refetch();
      reset();
    },
    onError: () => {
      setIsLoading(false);
      toast.error("Fail to add data");
      navigate(AppRoute.boardRoomDManagement.replace(":id", id as string), {
        state: TabTypes.RegistrationDesk,
      });
      refetch();
      reset();
    },
  });

  const { data: getRegistrationDeskDataById } = useGetRegistrationDeskById({
    client,
    id: registrationDeskId,
    options: {
      enabled: registrationDeskId !== "" ? true : false,
    },
  });

  const { data: registrationDesk, refetch } = useGetRegistrationDesk({
    client,
    id: id as string,
  });

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("RegFile");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
        localStorage.setItem("RegFile", reader.result as string);
      });
      // const thumbnail = localStorage.getItem("RegFile");
      // setThisFile(thumbnail);
    } catch (error: unknown) {
      throw Error;
    }
  };

  const onSubmit = async () => {
    setDisableSubmitButton(true);
    setIsLoading(true);
    if (file) {
      const boardRoomData = new FormData();
      boardRoomData.append("documents[]", file as any);
      boardRoomData.append("name", name);
      boardRoomData.append("description", description);
      boardRoomData.append("sort_order", index);

      await handleAddRegistrationDesk({
        client,
        body: boardRoomData,
        id: id as string,
      });
    }
    setDisableSubmitButton(false);

    localStorage.removeItem("RegFile");
  };

  const handleDeleteRegistrationDesk = async (id: number) => {
    await deleteRegistrationDesk({ client, id });
  };

  const { mutate: deleteRegistrationDesk } = useDeleteRegistrationDesk({
    onSuccess: () => {
      toast.success("Registration Desk Deleted Successfully");
      refetch();
    },
    onError: () => {
      toast.error("Unable to delete Registration Desk");
      refetch();
    },
  });

  return (
    <>
      <RegistrationDesk
        register={register}
        errors={errors}
        onDropHandleFunc={onDropHandleFunction}
        onSubmit={onSubmit}
        thumbnail={thisFile}
        registrationDesk={registrationDesk}
        setOpenContactsModal={setOpenContactsModal}
        setOpenAddModal={setOpenAddModal}
        isOpenContactsModal={isOpenContactsModal}
        isOpenAddModal={isOpenAddModal}
        handleDeleteRegistrationDesk={handleDeleteRegistrationDesk}
        openEditRegistrationDeskModal={openEditRegistrationDeskModal}
        setOpenEditRegistrationDeskModal={setOpenEditRegistrationDeskModal}
        registrationDeskId={registrationDeskId}
        setRegistrationDeskId={setRegistrationDeskId}
        getRegistrationDeskDataById={getRegistrationDeskDataById}
        disableSubmitButton={disableSubmitButton}
      />
      <ToastContainer />
      {isLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoadingIcon} alt="my-gif" />
        </div>
      )}
    </>
  );
};
