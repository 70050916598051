import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CreateBoardroomInputs } from "./EditBoardRoom.types";
import { CreateBoardroomSchema } from "./EditBoardRoom.utils";
import { useLocale } from "../../../hooks/useLocale/useLocale";
import { toast } from "react-toastify";
import { AppRoute } from "../../../routing/AppRoute.enum";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditBoardRoom,
  useGetBoardRoomById,
} from "../../../api/boardRoom/boardRoom";
import { useClient } from "../../../hooks/useClient/useClient";
import { CreateBoardRoom } from "./EditBoardRoom";
import { BoardRoomId } from "../../../routing/AppRoute.types";
import moment from "moment";

export const EditBoardRoomContainer = () => {
  const client = useClient();
  const { formatMessage } = useLocale();
  const navigate = useNavigate();

  const [thisFile, setThisFile] = useState<any>("");
  const [file, setFile] = useState<File>();
  const { id } = useParams<BoardRoomId>();
  const [dateOfInCoperation, setDateOfInCoperation] = useState<Date | null>(
    new Date()
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateBoardroomInputs>({
    resolver: CreateBoardroomSchema(formatMessage),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const {
    data: boardRooms,
    refetch,
    isLoading,
  } = useGetBoardRoomById({
    client,
    id: id,
  });
  const imageUrlToBase64 = useCallback(async () => {
    if (boardRooms?.data?.image) {
      const URL = boardRooms?.data?.image;
      const data = await fetch(URL);
      const blob = await data.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const file = new File([blob], boardRooms?.data?.name);
        setFile(file);
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
          setThisFile(base64data);
        };
        reader.onerror = reject;
      });
    } else {
      // Handle the case where getRegistrationDeskDataById or its properties are undefined
      console.error("Files data is undefined.");
      // Handle or return accordingly
    }
  }, [boardRooms]);

  useEffect(() => {
    if (boardRooms?.data.incorporation_date !== null) {
      setDateOfInCoperation(
        moment(boardRooms?.data.incorporation_date).toDate()
      );
    }
    imageUrlToBase64();
  }, [boardRooms, imageUrlToBase64]);

  const { name, description, address, whatsAppGroupLink } = watch();

  const { mutate: handleEditBoardRoom } = useEditBoardRoom({
    onSuccess: () => {
      toast.success("Boardroom updated successfully");
      navigate(AppRoute.manageBoardRoom);
      setDateOfInCoperation(new Date());
      setFile(undefined);
      setThisFile("");
      reset();
      refetch();
    },
    onError: () => {
      toast.error("Failed to update boardroom");
    },
  });

  const onDropHandleFunction = async (acceptedFiles: any) => {
    localStorage.removeItem("thumbnail");
    try {
      const acceptedFile: File = acceptedFiles[0];
      setFile(acceptedFile);
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.addEventListener("load", () => {
        setThisFile(reader.result);
      });
    } catch (error: unknown) {
      throw Error;
    }
  };

  const onSubmit = () => {
    // if (file) {
    const boardRoomData = new FormData();
    boardRoomData.append("image", file as any);
    boardRoomData.append("name", name);
    boardRoomData.append("description", description);
    boardRoomData.append("address", address);
    boardRoomData.append("whatsapp_link", `${whatsAppGroupLink}`);
    boardRoomData.append(
      "incorporation_date",
      moment(dateOfInCoperation).format("YYYY-MM-D")
    );
    handleEditBoardRoom({
      client,
      body: boardRoomData,
      id: id,
    });
    // }
  };

  return (
    <>
      {boardRooms?.data && !isLoading && (
        <CreateBoardRoom
          register={register}
          errors={errors}
          onDropHandleFunc={onDropHandleFunction}
          onSubmit={handleSubmit(onSubmit)}
          thumbnail={thisFile}
          boardRooms={boardRooms?.data}
          refetch={refetch}
          dateOfInCoperation={dateOfInCoperation}
          setDateOfInCoperation={setDateOfInCoperation}
        />
      )}
      {/* <ToastContainer autoClose={3000} /> */}
    </>
  );
};
