import {
  CreateDocumentProps,
  // CreateDocumentInputsTEST,
} from "./Document.types";
import { Button, Typography, DialogContent } from "../../ui/atoms";
import { Table } from "../../ui/organisms/table/Table";
import { Modal } from "../../ui/organisms/modal/Modal";
import { useStyles } from "./Document.styles";
import { useMemo, useState } from "react";
import { Column } from "react-table";
import { Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Input } from "../../ui/atoms/input/Input";
import { getAppUsers } from "../../api/document/Document.types";

export const Document = ({
  register,
  errors,
  onSubmit,
  handleDelete,
  setOpenAddModal,
  isOpenAddModal,
  refetch,
  documents,
}: CreateDocumentProps) => {
  const [isOpenContactsModal, setOpenContactsModal] = useState(false);
  const styles = useStyles();
  const [ids, setId] = useState("");

  // const columnsa = [
  //   {
  //     name: "Document 1",
  //     description: "Doc 1 desc",
  //     id: "1",
  //   },
  //   {
  //     name: "Document 2",
  //     description: "Doc 2 desc",
  //     id: "2",
  //   },
  //   {
  //     name: "Document 3",
  //     description: "Doc 3 desc",
  //     id: "3",
  //   },
  //   {
  //     name: "Document 4",
  //     description: "Doc 3 desc",
  //     id: "4",
  //   },
  // ];

  const columns: Column<getAppUsers>[] = useMemo(
    () => [
      {
        id: "name",
        Header: "Board Room Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>{value}</div>
        ),
      },
      {
        id: "id",
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <div className={styles.truncatedText}>
            <div
              className={styles.actionButtons}
              onClick={async (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                await setId(value as unknown as string);
                setOpenContactsModal(true);
              }}
            >
              <Tooltip title="Delete">
                <DeleteOutlineIcon color="error" />
              </Tooltip>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents?.data]
  );
  return (
    <div className={styles.mainDiv}>
      <Typography variant="h4" className={styles.heading}>
        Document Management
      </Typography>
      <div className={styles.buttonDiv}>
        <Button
          type="submit"
          size="large"
          variant="outlined"
          bold
          className={styles.submit}
          onClick={() => setOpenAddModal(true)}
        >
          Add Document
        </Button>
      </div>
      <Table
        columns={columns}
        data={documents?.data || []}
        className={styles.table}
      />
      {isOpenAddModal && (
        <Modal open={isOpenAddModal} onClose={() => setOpenAddModal(false)}>
          <form noValidate onSubmit={onSubmit} className={styles.cardForm}>
            <DialogContent>
              <div className={styles.head}>
                <div className={styles.rowDiv}>
                  <Typography
                    align="center"
                    variant="h4"
                    className={styles.lable}
                  >
                    Add Document
                  </Typography>
                  <Input
                    required
                    label="Name"
                    aria-label="name"
                    placeholder={"Enter name"}
                    name="name"
                    className={styles.input}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    register={register}
                  />
                  <Input
                    required
                    label="Description"
                    aria-label="description"
                    placeholder={"Enter description"}
                    name="description"
                    className={styles.input}
                    error={!!errors?.description}
                    helperText={errors?.description?.message}
                    register={register}
                  />
                </div>
              </div>
            </DialogContent>

            <Modal.Footer>
              <div className={styles.buttonsContainer}>
                <Button
                  type="button"
                  bold
                  size="large"
                  onClick={() => setOpenAddModal(false)}
                  variant="outlined"
                  className={styles.buttonyes}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  type="submit"
                  variant="outlined"
                  color="inherit"
                  bold
                  className={styles.closeButton}
                >
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isOpenContactsModal && (
        <Modal
          open={isOpenContactsModal}
          onClose={() => setOpenContactsModal(false)}
        >
          <DialogContent>
            <div className={styles.head}>
              <Typography align="center" variant="h5" className={styles.lable}>
                Are you sure you want to delete this document?
              </Typography>
            </div>
          </DialogContent>
          <Modal.Footer>
            <div className={styles.buttonsContainer}>
              <Button
                type="button"
                bold
                size="large"
                onClick={() => setOpenContactsModal(false)}
                variant="outlined"
                className={styles.closeButton}
              >
                No
              </Button>

              <Button
                size="large"
                type="button"
                variant="outlined"
                color="inherit"
                bold
                className={styles.buttonyes}
                onClick={async () => {
                  await handleDelete(Number(ids));
                  await refetch();
                  setOpenContactsModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
